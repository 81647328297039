import React, { Fragment } from 'react';
import classes from './FileUpload.module.css';
import { FileUploader } from 'react-drag-drop-files';
import BackupIcon from '@mui/icons-material/Backup';
import { Typography } from '@mui/material';
import { type IDataUploadFormValues } from '../../../../utils/types/ChatBot';

interface UploadProps {
  formValues: IDataUploadFormValues;
  handleFileChange: (file: File) => void;
}
const Upload: React.FC<UploadProps> = ({ handleFileChange, formValues }) => {
  const allowedFormats = ['xlsx'];

  const uploadButtonJSX = (
    <Fragment>
      <div className={classes.upload_icon__wrapper} style={{ height: '120px' }}>
        <BackupIcon sx={{ fontSize: '5em', color: 'primary', opacity: '0.3' }} />
        <Typography sx={{ fontFamily: 'sans-serif', fontSize: '.8em' }}>
          {formValues.file !== null ? `Selected File: ${formValues.file.name}` : 'Drop your file here...'}
        </Typography>
      </div>
    </Fragment>
  );

  return (
    <Fragment>
      <FileUploader
        handleChange={handleFileChange}
        name="file"
        types={allowedFormats}
        classes={classes.drop_zone}
        required
      >
        {uploadButtonJSX}
      </FileUploader>
    </Fragment>
  );
};

export default Upload;
