import { rebexApi } from './baseRebexEndpoints';
import type {
  IChatbotUploadData,
  IChatResponse,
  ICheckWorkspaceChatDataPayload,
  ICheckWorkspaceChatDataResponse,
  IDropdownOptionsResponse,
} from '../../types/ChatBot'; // Import the interface for type safety

interface IAPIStatusResponse {
  message: string;
}

export const chatbotApi = rebexApi.injectEndpoints({
  endpoints: (builder) => ({
    // Send a message to the chatbot and expect an IChatResponse
    sendMessage: builder.mutation<IChatResponse, { message: any }>({
      query: (payload) => ({
        url: '/chatbot/newrequest',
        method: 'POST',
        body: payload, // Send the message in the request body
      }),
    }),
    // Fetch a list of clients, returning a typed IDropdownOptionsResponse
    getUpdatedWorkspaceList: builder.query<IDropdownOptionsResponse, null>({
      query: () => ({
        url: '/chatbot/get_updated_workspace_list',
        method: 'GET',
      }),
    }),
    // Upload data to the server, expecting a string response
    uploadData: builder.mutation<IAPIStatusResponse, IChatbotUploadData>({
      query: ({ fileData, isClientData, clientName = '', surveyModuleName = '' }) => {
        const formData = new FormData();
        formData.append('excel_file', fileData);
        formData.append('is_client_data', String(isClientData));
        formData.append('client_name', clientName ?? '');
        formData.append('survey_module_name', surveyModuleName ?? '');

        return {
          url: '/chatbot/uploadData',
          method: 'POST',
          body: formData,
          contentType: 'multipart/form-data',
        };
      },
    }),
    checkWorkspaceChatData: builder.mutation<ICheckWorkspaceChatDataResponse, ICheckWorkspaceChatDataPayload>({
      query: ({ workspaceName }) => ({
        url: '/chatbot/is_benchmark_data_for_chat',
        method: 'POST',
        body: { workspace_name: workspaceName }, // Send the message in the request body
      }),
    }),
  }),
});

// Export hooks for usage in components
export const {
  useSendMessageMutation,
  useGetUpdatedWorkspaceListQuery,
  useUploadDataMutation,
  useCheckWorkspaceChatDataMutation,
} = chatbotApi;
