import { Box, type Theme, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AppCircularProgress from '../../components/materials/loading/AppCircularProgress';
import { useGetDefaultWorkspaceByUserQuery } from '../../utils/redux/api';
import { formatNameForPath } from '../../utils/stringUtils';

const RoleCheckAndRedirect = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingMessage, setLoadingMessage] = useState<string>('Checking role and access...');
  const { data } = useGetDefaultWorkspaceByUserQuery(null);

  useEffect(() => {
    if (data !== undefined) {
      const userRole: string = data.roles![0];
      localStorage.setItem('userWorkspaces', JSON.stringify(data.workspaces));
      switch (userRole) {
        case 'admin':
          setLoadingMessage('Admin detected, redirecting to workspaces page.');
          setTimeout(() => {
            navigate('/app/workspaces');
          }, 500);
          break;
        case 'mdp':
          setLoadingMessage('MDP detected, redirecting to workspaces page.');
          setTimeout(() => {
            navigate('/app/workspaces');
          }, 500);
          break;
        case 'data_lead':
          setLoadingMessage('Data Lead detected, redirecting to workspace.');
          if (data.workspaces !== undefined && data.workspaces.length > 0) {
            setTimeout(() => {
              navigate(
                `/app/workspaces/${formatNameForPath(data.workspaces![0].workspace_name)}/${data.workspaces![0].workspace_id}`,
                { replace: true },
              );
            }, 500);
          } else {
            setLoadingMessage('Data Lead detected, but no assignments found. Contact your system administrator.');
            setLoading(false);
          }
          break;
        case 'data_collector':
          setLoadingMessage('Data Collector detected, redirecting to workspace.');
          if (data.workspaces !== undefined && data.workspaces.length > 0) {
            setTimeout(() => {
              navigate(
                `/app/workspaces/${formatNameForPath(data.workspaces![0].workspace_name)}/${data.workspaces![0].workspace_id}`,
                { replace: true },
              );
            }, 500);
          } else {
            setLoadingMessage('Data Collector detected, but no assignments found. Contact your system administrator.');
            setLoading(false);
          }
          break;
        default:
          setLoading(false);
          setLoadingMessage('Error getting user access details, contact your system administrator.');
          break;
      }
    }
  }, [data, navigate]);

  return (
    <Box display="flex" flexDirection="column" gap={4} marginTop="60px" textAlign="center">
      {loading ? <AppCircularProgress /> : null}
      <Typography variant="bodyLarge" sx={{ color: (theme: Theme) => theme.palette.green[700] }}>
        {loadingMessage}
      </Typography>
    </Box>
  );
};

export default RoleCheckAndRedirect;
